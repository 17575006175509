<template>
    <v-tooltip top open-delay="700">
        <template v-slot:activator="{ on }">
            <v-btn
                class="mr-1 avsb-button"
                :color="btnColour"
                depressed
                :icon="btnIcon != null"
                :loading="btnIsLoading"
                :outlined="btnColour == null && btnIcon == null"
                :width="btnIcon == null ? '100px' : null"
                v-bind="$attrs"
                v-on="on"
                @click="$emit('click')"
            >
                <v-icon small>{{ btnIcon }}</v-icon>
                {{ btnLabel }}
            </v-btn>
        </template>
        <span>{{ tooltipMsg == null ? btnLabel : tooltipMsg }}</span>
    </v-tooltip>
</template>

<script>
export default {
    inheritAttrs: false,
    name: "customButton",
    props: {
        btnColour: { default: null },
        btnIcon: { default: null, type: String },
        btnIsLoading: { default: false, type: Boolean },
        btnLabel: { default: null },
        tooltipMsg: { default: null },
    },
    // Lifecycle hooks.
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
};
</script>
:icon="btnIcon != null"
